@tailwind base;
@tailwind components;
@tailwind utilities;

/* html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

input {
  box-shadow: none !important;
}

.englishFonts h1 {
  /* font-family: Gotham !important; */
  /* text-transform: uppercase; */
}

.englishFonts h2 {
  /* font-family: Gotham !important; */
  /* text-transform: uppercase; */
}

.englishFonts h3 {
  /* font-family: Gotham !important; */
  /* text-transform: uppercase; */
}

.englishFonts h4 {
  /* font-family: Gotham !important; */
  /* text-transform: uppercase; */
}

.englishFonts h5 {
  /* font-family: Gotham !important; */
  /* text-transform: uppercase; */
}

.englishFonts h6 {
  /* font-family: Gotham !important; */
  /* text-transform: uppercase; */
}

.englishFonts div {
  /* font-family: Tahoma !important; */
}

.englishFonts button {
  /* font-family: Gotham !important;? */
  /* text-transform: uppercase; */
}

/* .englishFonts p {
  font-family: Tahoma !important;
}

.englishFonts a {
  font-family: Tahoma !important;
}

.englishFonts span {
  font-family: Tahoma !important;
}

.englishFonts li {
  font-family: Tahoma !important;
}

.englishFonts ul {
  font-family: Tahoma !important;
}

.georgianFonts.capitalLetters {
  font-family: TBCContracticaCAPS !important;
}

.georgianFonts h1 {
  font-family: TBCContracticaCAPS !important;
}

.georgianFonts h2 {
  font-family: TBCContracticaCAPS !important;
}

.georgianFonts h3 {
  font-family: TBCContracticaCAPS !important;
}

.georgianFonts h4 {
  font-family: TBCContracticaCAPS !important;
}

.georgianFonts h5 {
  font-family: TBCContracticaCAPS !important;
}

.georgianFonts h6 {
  font-family: TBCContracticaCAPS !important;
}

.georgianFonts button {
  font-family: TBCContracticaCAPS !important;
} */

/* .georgianFonts div {
  font-family: Helvetica !important;
}

.georgianFonts p {
  font-family: Helvetica !important;
}

.georgianFonts a {
  font-family: Helvetica !important;
}

.georgianFonts span {
  font-family: Helvetica !important;
}

.georgianFonts li {
  font-family: Helvetica !important;
}

.georgianFonts ul {
  font-family: Helvetica !important;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300 !important;
}
.video-react-control-bar .video-react-fullscreen-control {
  font-family: Tahoma !important;
}

.video-react .video-react-control:before {
  width: 2rem;
}

.sc-qYiqT {
  margin: 0px 0px 0px 8px !important;
}

@font-face {
  font-family: 'GothamMd';
  src: url(./assets/fonts/GothamMedium.ttf);
  font-weight: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url(./assets/fonts/Gotham-Black.ttf);
  font-weight: bolder;
}
@font-face {
  font-family: 'Gotham';
  src: url(./assets/fonts/GothamBold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'TBCContracticaCAPS';
  src: url(./assets/fonts/TBCContracticaCAPS-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: 'TBCContracticaCAPS';
  src: url(./assets/fonts/TBCContracticaCAPS-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: 'TBCContracticaCAPS';
  src: url(./assets/fonts/TBCContracticaCAPS-Black.ttf);
  font-weight: bolder;
}

@font-face {
  font-family: 'HelveticLT';
  src: url(./assets/fonts/Helvetica.ttf);
}
@font-face {
  font-family: 'HelveticMd';
  src: url(./assets/fonts/HelveticaNeueLTGEOW82-65Md.ttf);
}

@font-face {
  font-family: 'Tahoma';
  src: url(./assets/fonts/TahomaRegularFont.ttf);
}

@font-face {
  font-family: 'Mtavruli';
  src: url(./assets/fonts/bpg_nino_mtavruli_bold.ttf);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}
.registrationInput:-webkit-autofill,
.loginInput:-webkit-autofill,
.registrationInput:-webkit-autofill:hover,
.loginInput:-webkit-autofill:hover,
.registrationInput:-webkit-autofill:focus,
.loginInput:-webkit-autofill:focus,
.registrationInput:-webkit-autofill:active,
.loginInput:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}

.landing {
  background: url('./assets/images/background-image.png') no-repeat center
    center/cover;
}

body {
  width: 100%;
  height: 100%;

  font-family: HelveticLT !important;

  /* font-family: HelveticLT; */
  /* font-family: HelveticMd !important; */

  background-color: #fcfcfc;
}
