.time-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border: 1px solid #080a0a08;
  border-radius: 8px;
  height: 56px;

  .time {
    margin-right: 10px;
    background-color: #080A0A04;
    width:20%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 10px;
    }
  }

  .input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    
  }
}