// @font-face {
//   src: url(../fonts/Helvetica-Regular.ttf) format(ttf);
//   font-family: 'Helvetica';
// }

// * {
//   font-family: Arial, Helvetica, sans-serif !important;
// }

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

ul {
  list-style: none;
}

ul,
figure {
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swal2-container {
  z-index: 1301 !important;
  .swal2 {
    &-modal {
      background: #ffffff !important;
      border-radius: 16px;
    }
    &-icon {
      display: none !important;
    }
    &-title {
      color: #080a0ad6;
      font-size: 32px;
      font-weight: 600;
    }
    &-actions {
      button {
        border-radius: 16px;
        padding: 16px 24px;
        &:first-child {
          background: #317bf4;
        }
        &:last-child {
          background-color: #080a0a0a !important;
          color: #cc3131;
          // border: 1px solid #f44336;
        }
      }
    }
  }
}

.MuiDialog-paper,
.MuiDialogContent-root {
  overflow-y: unset !important;
}

article {
  ul {
    list-style: inherit;
  }

  pre {
    margin: 0;
  }

  a {
    cursor: pointer;
    color: #5e93c5;
    &:hover {
      color: #7eadda;
    }
  }

  blockquote {
    background: #eee;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.MuiTabs-indicator {
  background-color: #317bf4 !important;
}

.container-md {
  width: 80%;
  margin: auto;
}

.purchase-container {
  width: 83%;
  margin: auto;
  background-color: #ffff !important;
}

@media screen and (min-width: 540px) {
  .purchase-container {
    background-color: #f1f3f8 !important;
  }
}
@media screen and (max-width: 900px) {
  .container-md {
    width: 90%;
  }
}

@media screen and (max-width: 1280px) {
  .purchase-container {
    width: 92%;
  }
}
