.skillTestReviewContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #0000000d;
  border-radius: 16px;
  opacity: 1;
  padding: 20px;
  margin: 20px auto;
  width: 670px;
  /* display: flex;
  align-items: flex-start; */
}

.reviewDetails {
  display: flex;
}

.reviewData {
  flex-grow: 1;
  margin-left: 15px;
}

.reviewDataSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reviewDataSection > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.username {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  /* font: normal normal bold 20px/24px Helvetica Neue LT GEO W82; */
  letter-spacing: 0px;
  color: #080a0ad6;
  text-transform: uppercase;
  opacity: 1;
}

.hoverableElement {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #080a0a84;
}

.hoverableElement:hover {
  color: #317bf4;
}

.profilePicture {
  width: 70px;
  height: 70px;

  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
}

.description {
  font-size: 15px;
  color: '#080A0A86';
  border-radius: 16px;

  text-align: left;
  /* font: normal normal medium 16px/24px Helvetica Neue LT GEO W82; */
  letter-spacing: 0px;
  color: #080a0a8a;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 16px;
}

.iconButton {
  border: 1px solid '#F1F1F1';
  border-radius: 15px;
}

.lowRating {
  letter-spacing: 0px;
  color: #cc3131;
  opacity: 1;
  margin-left: 10px;
}

.avatarIconButton {
  width: 70px;
  height: 70px;
  box-shadow: 0px 0px 30px #00000038;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-color: #a4a4a4;
  /* opacity: 1; */
  box-shadow: 0px 3px 6px #00000029;
  /* border-radius: 16px; */
  opacity: 1;
}

.avatarIcon {
  width: 30px;
  height: auto;
}
