.audioPlayerContainer {
  width: 770px;
  height: 250px;

  position: relative;
  overflow: hidden;

  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 0.8 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audioPlayer {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 8px 20px;
  box-sizing: border-box;

  width: 630px;
  height: 40px;
  background: #4d4d4d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.playPause {
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 14px;
  height: 16px;
}

.currentTime,
.duration {
  /* font: normal normal medium 13px/15px Helvetica Neue LT GEO W82; */
  letter-spacing: 0px;
  color: #ffffffd6;
}

.volumeBarParent {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
}

.progressBar {
  --bar-bg: #a6a6a6;
  --seek-before-width: 0;
  --seek-before-color: #c4c4c4;
  --knobby: #c4c4c4;

  --selectedKnobby: #c4c4c4;

  appearance: none;
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  outline: none;

  width: 345px;
  height: 10px;
}

.volumeProgressBar {
  width: 50px;
  height: 8px;
  background: var(--seek-before-color);
  border-radius: 2px;
}

/* progress bar - safari */
.progressBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 345px;
  height: 10px;
  outline: none;
}

.volumeProgressBar::-webkit-slider-runnable-track {
  width: 50px;
  height: 8px;
}

/* progress bar - firefox */
.progressBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 345px;

  height: 10px;
  outline: none;
}

.volumeProgressBar::-moz-range-track {
  width: 50px;
  height: 8px;
}

.progressBar::-moz-focus-outer {
  border: 0;
}

/* progress bar - chrome and safari */
.progressBar::before {
  content: '';
  height: 10px;
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

.volumeProgressBar::before {
  height: 8px;
}

/* progress bar - firefox */
.progressBar::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 10px;
}

.volumeProgressBar::-moz-range-progress {
  height: 8px;
}

/* knobby - chrome and safari */
.progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -2px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
}

.volumeProgressBar::-webkit-slider-thumb {
  height: 12px;
  width: 12px;
}

/* knobby while dragging - chrome and safari */
.progressBar:active::-webkit-slider-thumb {
  transform: scale(1.1);
  background: var(--selectedKnobby);
}

/* knobby - firefox */
.progressBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}

.volumeProgressBar::-moz-range-thumb {
  height: 12px;
  width: 12x;
}

/* knobby while dragging - firefox */
.progressBar:active::-moz-range-thumb {
  transform: scale(1.1);
  background: var(--selectedKnobby);
}

@media screen and (max-width: 770px) {
  .audioPlayerContainer {
    width: 90%;
  }
  .progressBar {
    width: 40vw;
  }
  .volumeProgressBar {
    width: 50px !important;
  }
}
@media screen and (max-width: 530px) {
  .progressBar {
    width: 30vw;
  }
  .audioPlayer {
    padding: 0;
  }
}
