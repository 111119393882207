.comment-wrapper {
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;

  .time {
    color: #797A7A;
    font-size: 1em;
  }
  .comment-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
  }
  .comment-description {
    font-size: 16px;
    color: #797A7A;
  }

  .actions {
    display: flex;
    justify-content: space-between;
  }

  .actions-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
} 