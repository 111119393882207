@font-face {
  font-family: 'Gotham Black';
  src: url(./Gotham-Black.ttf);
}

@font-face {
  font-family: 'Helvetica Regular';
  src: url(./Helvetica-Regular.ttf);
}

@font-face {
  font-family: 'Helvetica';
  src: url(./Helvetica.ttf);
}

@font-face {
  font-family: 'FiraGO Extra Bold';
  src: url(./FiraGO-ExtraBold.ttf);
}

@font-face {
  font-family: 'Geo Roboto Mt Bold';
  src: url(./roboto-geo-mt-bold.ttf);
}
