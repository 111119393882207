.btn-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  .MuiButton-label {
    white-space: nowrap;
  }
  .MuiButton-root {
    width: 100%;
    color: #fff;
    background: #ff3a52;
    padding: 10px;
    text-transform: initial;

    &:hover {
      background: #ff5c71;
    }
  }

  .MuiButton-textSecondary {
    background: #2f2834;

    &:hover {
      background: lighten(#2f2834, 15%);
    }
  }

  .Mui-disabled {
    background: rgba(0, 0, 0, 0.3);
  }
}

.btn {
  &--small .MuiButton-root {
    font-size: 11px;
    padding: 6px;
  }

  &--medium {
    .MuiButtonBase-root {
      border-radius: 16px;
      color: #ffffff;
      font-size: 16px;
      padding: 8px 16px;
    }
  }

  &--profile-actions .MuiButton-root {
    font-size: 14px;
    padding: 6px 12px;
    background: #2b282b;

    &:hover {
      background: #ff3a52;
    }
  }
}
