.tooltip {
  width: 212px;
  height: 67px;
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .inner-box {
    width: 80%;
    height: 30px;
    background: rgba($color: #707070, $alpha: 0.05);
    opacity: 1;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #317bf4;
  }
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: -9px;

  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;

  border-top: 15px solid #ffffff;
}
