.legend-holder {
  height: 60px;
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
  align-items: center;

  .legend-bl {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 21px;
      height: 20px;
      background: #5290f6;
      margin: 10px;
    }
  }

  .legend-or {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 21px;
      height: 20px;
      background: #ffb42b;
      margin: 10px;
    }
  }

  .legend-gr {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 21px;
      height: 20px;
      background: #e0e3e7;
      margin: 10px;
    }
  }
}
